.login_input_cont{
  margin-top: 50px;
    flex-direction: column;
    display: flex;
}
.flex_column{
  flex-direction: column;
  display: flex;
}
.loginCont {
  width: 95vh;
  height: 55vh;
  background-color: #181818;
  z-index: 8;
  border-radius: 20px;
  margin-right: 8%;
  opacity: 0.9;
  padding: 10px;
}

.login_input{
  width:400px;
  height: 30px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid white;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    background-color: black;
    margin-top: 20px;
    color: white;
}

.title_1{
  color: white;
    font-size: 25px;
    font-weight: 900;
    text-align: center;
}
.title_loading{
  color: rgb(31, 39, 49);
    font-size: 25px;
    font-weight: 900;
    text-align: center;
}
.admin_main_container{
  display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    z-index: 1;
    height: 100vh;
}
.admin_left_bg_img{
  width: 100%;
    position: absolute;
    height: 100vh;
    background-image: url('../assets/bgImages/bg2.jpeg');
    background-size: contain;
    background-position: left center;
    filter: blur(3px);
    background-repeat: no-repeat;
}
.admin_rigt_bg_img {
  width: 80%;
    position: absolute;
    height: 100vh;
    background-image: url('../assets/bgImages/bg3.jpg');
    background-size: cover;
    filter: blur(3px);
    opacity: 0.7;
    z-index: -1;
    right: 0px;
}
.black_bg_op{
  width: 100%;
    position: absolute;
    height: 100vh;
    background-color: black;
    opacity: 0.3;
}
.text_1{
  color: white;
    font-size: 18px;
    font-weight: normal;
}
.header_title{
  color: black;
  font-size: 24px;
  font-weight: bold;
  margin-right: 50px;
}
.title_2{
  color: white;
    font-size: 16px;
    font-weight: normal;
}
.login_button{
  width:200px;
  height: 40px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid white;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    background-color: black;
    margin-top: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login_button:hover {
  opacity: 0.3;
  cursor: pointer;

}
.remove_button{
  /* width:50px; */
  height: 20px;
    /* padding: 10px; */
    padding-left: 30;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid white;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    background-color: black;

    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.remove_button:hover {
  opacity: 0.3;
  cursor: pointer;

}
.edit_button{
  width:80px;
  height: 30px;
    padding: 10px;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid white;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    background-color: black;
    margin-right: 10px;
    margin-bottom: 3px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit_button:hover {
  opacity: 0.3;
  cursor: pointer;

}
.black_bg_op_admin_panel{
  width: 105vh;
  max-height: 90vh;
  height: 90vh;
  background-color: black;
  z-index: 8;
  border-radius: 20px;
  opacity: 0.9;
  padding: 10px;
  position: absolute;
  top: 50px;
  right: 20px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.black_bg_op_map_title{
  background-color: black;
  z-index: 8;
  border-radius: 5px;
  opacity: 0.7;
  padding: 10px;
  position: absolute;
  bottom: 5px;
min-width: 100px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
font-size: 16px;
color:aliceblue

}
.adding_box{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.autoComplite_input {
  width: 40%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid rgb(204, 204, 204);
  outline: none;
  background-color:  #181818;
  color: white;
  margin-top: 10px;
}
.adding_box_content{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.adress_text{
  width: 50%;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.adding_box_speed{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
}
.all_datas{
    display: flex;
    align-items: center;
    width: 100%;
}
.distance_futureDate{
  display: flex;
    flex-direction: column;
    width: 100%;
    align-self: normal;
    justify-content: space-between;
}
.truck_list_items{
display: flex;
border: 0.8px solid white;
padding: 8px 7px;
border-radius: 5px;
margin-top: 5px;
}
.truck_list_items_finish{
  display: flex;
  border: 0.8px solid greenyellow;
  padding: 8px 7px;
  border-radius: 5px;
  margin-top: 5px;
  background-color: rgb(178, 196, 179);
  cursor: pointer;
  }
.truck_list_items_selected{
  display: flex;
  border: 0.8px solid white;
  padding: 8px 7px;
  border-radius: 5px;
  margin-top: 5px;
  background-color: rgb(23, 32, 40);
  cursor: pointer;
  }
  
  .truck_list_items_finish:hover{
    background-color: rgb(137, 192, 141);
    cursor: pointer;
    }
.truck_list_items:hover{
  background-color: rgb(23, 32, 40);
  cursor: pointer;
  }
  .speed_input{
    max-width: 100px;
  }
  .titleContent{
    border-width: 0;
  }
  .title_bold{
    font-weight: 900;
  }
  .flex_just{
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
.button_defoult{
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.header_block{
  height: 10vh;
  width: 100%;
  background-color: white;

  position: absolute;
  z-index: 5;
top: 0;
display: flex;
align-items: center;
}
.header_block_bottom{
  height: 10vh;
  width: 100%;
  background-color: #03254C;

  position: absolute;
  z-index: 5;
bottom: 0;
display: flex;
align-items: center;
}
.header_block_container{
  padding: 15px 50px;
  display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
}
.titles_inputs{
  display: flex;
  justify-content: flex-start;
}
.w_50{
  width: 50%;
}
.w_90{
  width: 90%;
}
.header_title_container{
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
}
.header_logo{
  width: 70px;
  height: 70px;
}
@media (max-width: 599px) {
  .header_block_container{
    padding: 15px 10px;
    display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  }
  .header_logo{
    width: 50px;
    height: 50px;
  }
  .header_title{
    color: black;
    font-size: 18px;
    font-weight: bold;
    margin-right: 30px;
  }
}